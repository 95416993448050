import React from 'react';
import '../styles/home.css'
import { Link } from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';
import { TiFlashOutline } from 'react-icons/ti';
import { FiDownloadCloud } from 'react-icons/fi';
import { SiSlideshare } from 'react-icons/si';
import { PiPlugsConnected } from 'react-icons/pi';
import { TbMessages } from 'react-icons/tb';
import { BiVideoRecording } from 'react-icons/bi';
import { motion } from "framer-motion"


export default function Home() {

    const features = [
        {
          name: 'Advanced real-time video technology for ultra-low latency and crystal clear video and audio.',
          icon: TiFlashOutline,
        },
        {
          name: 'Download desktop and for Windows, Mac, Android and iOS.',
          icon: FiDownloadCloud,
        },
        {
          name: 'Screen sharing, real time interactions and document sharing to get work done effectively.',
          icon: SiSlideshare,
        },
        {
          name: 'Global coverage, connect with anyone, from anywhere at anytime.',
          icon: PiPlugsConnected,
        },
        {
          name: 'One on One and group messaging from anywhere at anytime.',
          icon: TbMessages,
        },
        {
          name: 'Easy to use meeting recordings.',
          icon: BiVideoRecording,
        },
      ]

  return (
    <HomeLayout>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <main>
        <section className='main-home lg:min-h-screen h-auto py-5 main w-full mx-auto pt-0 md:pt-6' style={{backgroundImage: 'url("./images/home-banner.webp")', transition: .25}}>
            <div className="flex flex-col relative mx-auto w-full sm:static">
            <div className="w-full pb-10 pt-6 lg:pt-0 text-center">
                <div>
                    <div className='text-5xl lg:text-6xl font-bold mb-5 text-main leading-snug'>Meet now on Chirphost</div>
                    <p>Meet with your friends or stay connected with your team remote from anywhere.</p>
                    <div className='flex flex-row justify-center space-x-5 mt-12 md:mt-0'>
                    <Link to="/signup" className='btn-main'>Signup for Free</Link>
                    <Link to="/signup" className='btn-main-alt'>Watch the Video</Link>
                    </div>
                </div>
            </div>
            <div className='w-full max-w-screen-2xl mx-auto pt-6 pb-10 md:py-0'>
                <div className="relative w-11/12 lg:w-6/12 mx-auto">
                    <img className="w-full h-full object-contain right-0 top-0" src="/images/main-banner.webp" alt="Home" />
                </div>
            </div>
            </div>
        </section>
        <section class="bg-main-alt">
            <div class="py-12 px-6 2xl:px-10 mx-auto container sm:py-16">
                <div class="max-w-screen-md mb-8 lg:mb-16">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-main">Get started in 3 easy steps.</h2>
                </div>
                <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                    <div>
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-white text-neutral-800 text-lg font-bold">
                            1
                        </div>
                        <h3 class="mb-2 text-xl font-bold text-neutral-900">Start an Instant Meeting</h3>
                        <p class="text-neutral-700 text-sm leading-loose font-light">When you enter the meeting room you will see the automatically generated meeting link to copy.</p>
                    </div>
                    <div>
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-white text-neutral-800 text-lg font-bold">
                            2
                        </div>
                        <h3 class="mb-2 text-xl font-bold text-neutral-900">Send an Invitation</h3>
                        <p class="text-neutral-700 text-sm leading-loose font-light">Share the meeting room ID or the URL link via email, chat, slack, etc.</p>
                    </div>
                    <div>
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-white text-neutral-800 text-lg font-bold">
                            3
                        </div>
                        <h3 class="mb-2 text-xl font-bold text-neutral-900">Start your Meeting</h3>
                        <p class="text-neutral-700 text-sm leading-loose font-light">Enter the room ID or directly click the URL link to join the meeting.</p>
                    </div>
                </div>
            </div>
        </section>
        <div className="overflow-hidden whychooseus bg-white py-20">
            <div className="px-6 2xl:px-10 mx-auto container">
                <div className="mx-auto grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div className="lg:pr-8">
                    <div className="lg:max-w-lg">
                    <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Why choose Chirphost ?</p>
                    <dl className="mt-10 max-w-xl space-y-8 text-md leading-7 text-gray-600 lg:max-w-none">
                        {features.map((feature) => (
                        <div key={feature.name} className="relative pl-9">
                            <dt className="inline text-sm font-medium text-gray-700">
                            <feature.icon className="absolute left-1 top-1 h-5 w-5 text-main" aria-hidden="true" />
                            {feature.name}
                            </dt>{' '}
                        </div>
                        ))}
                    </dl>
                    </div>
                </div>
                <div className='flex items-center'>
                    <img src="/images/home-features.webp" alt="Product screenshot" className="hidden lg:block w-full" />
                </div>
                </div>
            </div>
        </div>
        </main>
        </motion.div>
    </HomeLayout>
  )
}
