import React from 'react';
import {Link} from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';
import { motion } from "framer-motion"

export default function Features() {
    return (
    <HomeLayout>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <main>
        <section class="bg-white">
            <div class="pb-8">
                <div class="text-center mb-8 lg:mb-12 bg-main-alt pt-16 pb-32">
                    <div className='max-w-screen-lg px-5 mx-auto'>
                        <h2 class="mb-4 text-4xl leading-tight lg:leading-normal font-extrabold text-black">Join the Chirphost revolution and elevate your video meetings today!</h2>
                    </div>
                </div>
                <div className='container mx-auto px-0 lg:px-14 -mt-36'>
                    <img src="/images/features.webp" alt="Product screenshot" className="w-11/12 lg:w-9/12 mx-auto" />
                </div>
            </div>
        </section>
        <section className='features container mx-auto px-0 2xl:px-10 mt-10 mb-0 md:mt-14 md:mb-14'>
        <section className='feature flex flex-col md:flex-row'>
            <div className='w-2/2 md:w-1/2'>
                <img src='/images/features1.webp' className='w-9/12 mx-auto h-full object-cover'/>
            </div>
            <div className='w-2/2 md:w-1/2 p-6 lg:p-20'>
                <h4 className='heading'>Hosting Meetings of any Size</h4>
                <p className='text-neutral-500 text-lg mt-3 mb-10'>Effortlessly host meetings of any size with our versatile video chat application.</p>
            </div>
        </section>
        <section className='feature flex flex-col md:flex-row'>
            <div className='w-2/2 md:w-1/2 p-6 lg:p-20 order-2 md:order-1'>
                <h4 className='heading'>Scheduling Meetings on Calendar</h4>
                <p className='text-neutral-500 text-lg mt-5 mb-10'>Stay organized by scheduling and managing your meetings within our integrated calendar feature.</p>
            </div>
            <div className='w-2/2 md:w-1/2 order-1 md:order-2'>
                <img src='/images/features2.webp' className='w-8/12 mx-auto h-full object-cover'/>
            </div>
        </section>
        <section className='feature flex flex-col md:flex-row'>
            <div className='w-2/2 md:w-1/2'>
                <img src='/images/features3.webp' className='w-8/12 mx-auto h-full object-cover'/>
            </div>
            <div className='w-2/2 md:w-1/2 p-6 lg:p-20'>
                <h4 className='heading'>Easily Record Meeting Moments</h4>
                <p className='text-neutral-500 text-lg mt-3 mb-10'>Capture important discussions and presentations with our easy-to-use recording feature.</p>
            </div>
        </section>
        <section className='feature flex flex-col md:flex-row'>
            <div className='w-2/2 md:w-1/2 p-6 lg:p-20 order-2 md:order-1 feature4bg'>
                <h4 className='heading'>One on One &amp; Group Messaging of any Size</h4>
                <p className='text-neutral-500 text-lg mt-3 mb-10'>Engage in real-time conversations with individuals or groups of any size using our messaging system.</p>
            </div>
            <div className='w-2/2 md:w-1/2 order-1 md:order-2'>
                <img src='/images/features4.webp' className='w-9/12 mx-auto h-full object-cover'/>
            </div>
        </section>
        </section>
        </main>
        </motion.div>
    </HomeLayout>
    )
}