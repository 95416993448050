import '../styles/footer.css'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className='bg-neutral-100'>
        <div class="mx-auto w-full">
            <div class="container px-6 2xl:px-10 mx-auto py-16 md:flex md:justify-between">
              <div class="mb-14 md:mb-0">
                  <Link to="/" class="flex items-center">
                      <img src="./logo.png" class="h-16" alt="Chirphost Logo" />
                  </Link>
                    <p class="text-neutral-700 text-sm leading-loose font-medium my-3">Is a video conferencing &amp; messaging software developed by <a target="_blank" className='hover:underline text-blue-500'  href="https://perplexityonline.com/">Perplexity</a>.</p>
                    <div>
                        <Link to="#" class="btn-main !text-neutral-600">Get Started - <span className='text-black'>It's easy</span></Link>
                    </div>
              </div>
              <div class="grid grid-cols-1 gap-14 sm:gap-16 sm:grid-cols-3">
                  <div>
                      <h2 class="mb-4 text-lg tracking-wider font-semibold uppercase text-main">Useful Links</h2>
                      <ul class="text-neutral-700 font-normal space-y-2 lg:space-y-4">
                          <li>
                              <Link to="/downloads" class="hover:underline">Downloads</Link>
                          </li>
                          <li>
                              <Link to="/pricing" class="hover:underline">Pricing</Link>
                          </li>
                          <li>
                              <Link to="/features" class="hover:underline">Features</Link>
                          </li>
                          <li>
                              <Link to="#" class="hover:underline">Careers</Link>
                          </li>
                      </ul>
                  </div>
                  <div>
                      <h2 class="mb-4 text-lg tracking-wider font-semibold uppercase text-main">Social Links</h2>
                      <ul class="text-neutral-700 font-normal space-y-2 lg:space-y-4">
                          <li>
                              <a target="_blank" href="https://www.facebook.com/Chirphost" class="hover:underline">Facebook</a>
                          </li>
                          <li>
                              <a target="_blank" href="https://www.linkedin.com/company/perplexityonline" class="hover:underline">Linked In</a>
                          </li>
                          <li>
                              <a target="_blank" href="https://twitter.com/Chirphost" class="hover:underline">Twitter</a>
                          </li>
                      </ul>
                  </div>
                  <div>
                      <h2 class="mb-4 text-lg tracking-wider font-semibold uppercase text-main">Get in Touch</h2>
                      <ul class="text-neutral-700 font-normal space-y-2 lg:space-y-4">
                          <li>
                              <Link to="#" class="hover:underline">Submit a Ticket</Link>
                          </li>
                      </ul>
                  </div>
              </div>
            </div>
            <div className='bg-main-alt px-6 2xl:px-10'>
                <div class="container mx-auto sm:flex sm:items-center sm:justify-between py-6">
                <span class="text-sm sm:text-center text-neutral-400">© 2023 <Link to="/" class="hover:underline site-title">Chirphost</Link>. All Rights Reserved.</span>
                    <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0 text-sm">
                        <Link to="/privacy" class="text-neutral-500 hover:text-neutral-900">Privacy Policy</Link>
                        <Link to="/terms" class="text-neutral-500 hover:text-neutral-900">Terms and Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>


  )
}
